<template>
  <v-container fluid class="ma-0 pa-0 light-background">
    <v-layout row wrap pa-5>
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-card class="pb-3" flat tile color="transparent">
          <v-card-text class="pa-0">
            <v-layout row wrap>
              <v-flex xs12 sm6 pb-5>
                <p
                  class="graphik-bold primary--text mb-0"
                  :class="{
                    'font-60': $vuetify.breakpoint.mdAndUp,
                    'font-45': $vuetify.breakpoint.smAndDown
                  }"
                >{{ $ml.get('general_error') }} 404.</p>
                <p
                  class="graphik-light dark-grey--text mb-4"
                  :class="{
                    'font-30': $vuetify.breakpoint.mdAndUp,
                    'font-17': $vuetify.breakpoint.smAndDown
                  }"
                >{{ $ml.get('error_404') }}</p>

                <v-btn 
                  color="primary"
                  depressed dark 
                  class="normalcase graphik-bold-italic ma-0 border-radius-5"
                >{{ $ml.get('general_go_back_home') }}</v-btn>
              </v-flex>

              <v-flex xs12 sm6>
                <v-img
                  width="100%"
                  :src="image"
                  :lazy-src="image"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'NotFound',
  data () {
    return {
      image: require('@/assets/img/images/cuco_tool.png')
    }
  },
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_NOT_FOUND,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_NOT_FOUND
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_NOT_FOUND
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_NOT_FOUND
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_NOT_FOUND
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_NOT_FOUND
      }
    ]
  }
}
</script>

<style scoped>
</style>
